import React from 'react';

const Footer = () => {
  return (
    <div className="flex pb-1 border-t border-[#e4e4e4] py-3">
      <div className="container mx-auto max-w-screen-xl">
        <div className="flex items-center justify-center ">
          <p className="text-sm text-black font-bold">
            ©2024 Developed by Aquazeel. Powered by{' '}
            <span className="text-blue-500 font-bold">
              Digital Ocean and LLAMA 3.3
            </span>{' '}
            <span className="font-normal">All rights reserved</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
