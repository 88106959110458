// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main__title {
    margin: 30px 0;
    font-size: 25px;
    font-weight: 300;
}

.main__button {
    all: unset;

    font-size: 14px;

    padding: 10px 25px;
    margin: 20px 0;

    color: white;
    background-color: var(--main-black);

    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,UAAU;;IAEV,eAAe;;IAEf,kBAAkB;IAClB,cAAc;;IAEd,YAAY;IACZ,mCAAmC;;IAEnC,mBAAmB;AACvB","sourcesContent":[".main__title {\n    margin: 30px 0;\n    font-size: 25px;\n    font-weight: 300;\n}\n\n.main__button {\n    all: unset;\n\n    font-size: 14px;\n\n    padding: 10px 25px;\n    margin: 20px 0;\n\n    color: white;\n    background-color: var(--main-black);\n\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
