import React from 'react';
import Header from './Header';
import './HeaderOverlay.css';

function HeaderOverlay({ children }) {
  return (
    <div className="header-overlay">
      <Header />
      {children}
    </div>
  );
}

export default HeaderOverlay;
