// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: var(--main-color);
    height: 100px;
    box-sizing: border-box;
}

.header__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.header__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.header__notifications-text,
.header__profile-text
{
    font-size: 25px;
    font-weight: 400;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb;;AAEA;;;IAGI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".header {\n    background-color: var(--main-color);\n    height: 100px;\n    box-sizing: border-box;\n}\n\n.header__container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    height: 100%;\n    box-sizing: border-box;\n}\n\n.header__right {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 20px;\n}\n\n.header__notifications-text,\n.header__profile-text\n{\n    font-size: 25px;\n    font-weight: 400;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
