// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 100%;
  border-collapse: collapse;
}

.table__grid {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  color: white;
}

.table__header {
  background: linear-gradient(to right, #0e4bf2, #60a5fa);
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.table__body {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  gap: 10px;
}

.table__element {
  background: linear-gradient(to right, #0e4bf2, #60a5fa);
  padding: 10px 20px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.table__element:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,uDAAuD;EACvD,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,wDAAwD;EACxD,SAAS;AACX;;AAEA;EACE,uDAAuD;EACvD,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,0CAA0C;EAC1C,qDAAqD;AACvD;;AAEA;EACE,2BAA2B;EAC3B,2CAA2C;EAC3C,eAAe;AACjB","sourcesContent":[".table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table__grid {\n  display: grid;\n  grid-template-columns: 3fr 2fr 2fr;\n  color: white;\n}\n\n.table__header {\n  background: linear-gradient(to right, #0e4bf2, #60a5fa);\n  padding: 10px 20px;\n  margin-bottom: 15px;\n  border-radius: 8px;\n  font-size: 1.2rem;\n  font-weight: bold;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.table__body {\n  display: grid;\n  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));\n  gap: 10px;\n}\n\n.table__element {\n  background: linear-gradient(to right, #0e4bf2, #60a5fa);\n  padding: 10px 20px;\n  margin-bottom: 5px;\n  border-radius: 8px;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.table__element:hover {\n  transform: translateY(-3px);\n  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
