import {useState , createContext } from "react";

export const ModalsContext = createContext();

export function ModalsProvider(props) {
    const [modals, setModals] = useState({
        aiChat: false,
    });

  return (
    <ModalsContext.Provider value={[modals, setModals]}>
      {props.children}
    </ModalsContext.Provider>
  );
}
