import React, { useContext } from 'react';
import './Table.css';
import { FundContext } from '../../context/FundContext';

function Table() {
  const [funds] = useContext(FundContext);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="table">
      <div className="table__header">
        <div className="table__grid">
          <p className="table__header-text">Fund Name</p>
          <p className="table__header-text">Group Name</p>
          <p className="table__header-text">Investment Amount</p>
        </div>
      </div>
      <div className="table__body">
        {funds.map((element, index) => (
          <div className="table__element" key={index}>
            <div className="table__grid">
              <p className="table__body-text">{element.name}</p>
              <p className="table__body-text">{element.group}</p>
              <p className="table__body-text">
                {USDollar.format(element.amount)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Table;
