// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  margin-top: 135px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.input-group {
  margin-bottom: 15px;
}

.login-input {
  width: 327px;
  height: 60px;
  padding: 16px 221px 16px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid var(--Off-Black, #1d1d1f);
  background: var(--Off-White, #fafafc);
  font-size: 24px;
}

.login-button {
  width: 325px;
  height: 60px;

  display: inline-flex;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: var(--Off-Black, #1d1d1f);

  color: var(--Off-White, #fafafc);
  /* Heading/H3 Bold */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  transition: all ease-in-out 0.3s;
  /* 133.333% */
}

.login-button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,2CAA2C;EAC3C,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;;EAEZ,oBAAoB;EACpB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,qCAAqC;;EAErC,gCAAgC;EAChC,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".login-container {\n  margin-top: 135px;\n  color: #000;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n}\n\n.input-group {\n  margin-bottom: 15px;\n}\n\n.login-input {\n  width: 327px;\n  height: 60px;\n  padding: 16px 221px 16px 24px;\n  align-items: center;\n  flex-shrink: 0;\n  border-radius: 16px;\n  border: 2px solid var(--Off-Black, #1d1d1f);\n  background: var(--Off-White, #fafafc);\n  font-size: 24px;\n}\n\n.login-button {\n  width: 325px;\n  height: 60px;\n\n  display: inline-flex;\n  padding: 14px 32px;\n  justify-content: center;\n  align-items: center;\n  gap: 16px;\n  border-radius: 16px;\n  background: var(--Off-Black, #1d1d1f);\n\n  color: var(--Off-White, #fafafc);\n  /* Heading/H3 Bold */\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 32px;\n  transition: all ease-in-out 0.3s;\n  /* 133.333% */\n}\n\n.login-button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
