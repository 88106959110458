// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-overlay {
  display: flex;

  height: 83vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/SidebarOverlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,YAAY;AACd","sourcesContent":[".sidebar-overlay {\n  display: flex;\n\n  height: 83vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
