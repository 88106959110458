import React from 'react';
import Table from './Table';
import './MainPage.css';
import TotalCalc from './TotalCalc';
import SidebarOverlay from '../../components/Sidebar/SidebarOverlay';
function MainPage() {

  return (
    <div className="flex">
      <SidebarOverlay />
      <main className="main w-full h-[200px]">
        <div className="global__container">
          <h3 className="mx-0 my-7 text-2xl font-medium mb-9">
            Portfolio <span className="text-blue-500">Construction</span>
          </h3>
          <Table />
          <button className=" my-5 px-10 py-[6px] transition-all duration-300 ease-in-out border border-[#dadada] bg-white text-black rounded-lg hover:bg-blue-500 hover:text-white cursor-pointer hover:border-[#3b82f6]">
            Add Fund
          </button>
          <TotalCalc />
        </div>
        {/* {modals.aiChat && <AiChatModal />}
        {!modals.aiChat && <AiChatModalButton />} */}
      </main>
    </div>
  );
}

export default MainPage;
