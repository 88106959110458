import React from 'react';
import Sidebar from './Sidebar.jsx';
import './SidebarOverlay.css';

function SidebarOverlay({ children }) {
  return (
    <div className="sidebar-overlay">
      <Sidebar />
      {children}
    </div>
  );
}

export default SidebarOverlay;
