import HeaderOverlay from './components/Header/HeaderOverlay';
import MainPage from './components/MainPage/MainPage';
import Login from './components/Login/Login';
import { FundProvider } from './context/FundContext';
import { ModalsProvider } from './context/ModalsContext';
import './reset.css';
import React, { useState } from 'react';
import Footer from './components/Footer/Footer';

function App() {
  const [setLoggedIn] = useState(true);

  const handleLogin = () => {
    setLoggedIn(true);
  };
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  return (
    <FundProvider>
      <ModalsProvider>
        {isLoggedIn ? (
          <HeaderOverlay>
            <MainPage />
            <Footer />
          </HeaderOverlay>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </ModalsProvider>
    </FundProvider>
  );
}

export default App;
