import React from 'react';
import './Header.css';
import Logo from '../../assets/logo.png';
function Header() {
  return (
    <div className="w-full items-center py-4 justify-between text-black border-b border-[#e4e4e4] ">
      <div className="global__container">
        <div className="flex w-full  items-center justify-between text-black ">
          <div className="flex text-2xl font-bold  items-center gap-1">
            <img className="w-14 h-14" src={Logo} alt="Logo" />
            <span className="text-blue-500">AI Chatbot </span>Finance
          </div>
          <div className="flex items-center gap-3">
            <p className="cursor-pointer text-lg">Notifications</p>
            <p className="cursor-pointer text-lg">Profile</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
