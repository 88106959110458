// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: var(--main-color);
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar__header {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar__header-text {
    font-size: 35px;
    font-weight: 700;
    color: #000000;
}

.sidebar__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.sidebar__nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".sidebar {\n    background-color: var(--main-color);\n    width: 200px;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.sidebar__header {\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.sidebar__header-text {\n    font-size: 35px;\n    font-weight: 700;\n    color: #000000;\n}\n\n.sidebar__main {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n    text-align: center;\n    font-size: 20px;\n    font-weight: 700;\n}\n\n.sidebar__nav {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
