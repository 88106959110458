import React, { useContext, useEffect, useState } from 'react';
import { FundContext } from '../../context/FundContext';
import './TotalCalc.css';

function TotalCalc() {
  const [funds] = useContext(FundContext);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalAmount = 0;
    funds.forEach((fund) => {
      totalAmount += fund.amount;
    });
    setTotal(totalAmount);
  }, [funds]);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="total">
      <p className="text-lg">Your total invesment amount:</p>

      <p className="text-lg">{USDollar.format(total)}</p>
    </div>
  );
}

export default TotalCalc;
