//add context to the app

import React, { useState, createContext } from "react";

export const FundContext = createContext();

export const FundProvider = (props) => {

    const [funds, setFunds] = useState([
        {
          name: "Fund One",
          group: "Group One",
          amount: 10000,
        },
        {
          name: "Fund Two",
          group: "Group Two",
          amount: 20000,
        },
        {
          name: "Fund Three",
          group: "Group Three",
          amount: 30000,
        },
        {
          name: "Fund Four",
          group: "Group Four",
          amount: 40000,
        },
      ]);

    return (
        <FundContext.Provider value={
            [funds, setFunds]
        }>
            {props.children}
        </FundContext.Provider>
    );
};