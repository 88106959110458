import React from 'react';
import './Sidebar.css';

function Sidebar() {
  const slideArr = ['Dashboard', 'Portfolio Manager', 'Legal', 'Loan'];
  return (
    <div
      className="w-[200px] py-10 justify-between flex flex-col items-center h-full border-r border-[#e4e4e4]
    "
    >
      <nav className="">
        {slideArr.map((el, id) => (
          <p className="mb-5 text-lg font-medium cursor-pointer" key={id}>
            {el}
          </p>
        ))}
      </nav>

      <div className="flex items center gap-2">
        <p className="sidebar__footer-text">Support</p>
        <p className="sidebar__footer-text">FAQs</p>
      </div>
    </div>
  );
}

export default Sidebar;
// <div className="sidebar">
//   <div className="sidebar__main">
//     <nav className="sidebar__nav">
//       <p className="sidebar__nav-item">Dashboard</p>

//       <p className="sidebar__nav-item">Portfolio Manager</p>

//       <p className="sidebar__nav-item">Legal</p>

//       <p className="sidebar__nav-item">Loan</p>
//     </nav>

//     <div className="sidebar__footer">
//       <p className="sidebar__footer-text">Support</p>

//       <p className="sidebar__footer-text">FAQs</p>
//     </div>
//   </div>
// </div>
